<template>
    <styled-slideout
        class="alert-slideout"
        :value="value"
        :width="600"
        @input="$emit('input', $event)">
        <template>
            <div class="form-container">
                <h2>Please fill the form bellow</h2>
                <p class="form-description">
                    If you are interested in signing up for the MarketAnalyzer White Labeled product, please fill out the form below and you will be contacted by a member of our Sales team to work through the process
                </p>
                <div v-if="!formSubmitted">
                    <hub-spot-form
                        ref="hsForm"
                        form-id="74a8dd59-3a7f-491a-993e-c44a7f1261f5"
                        :field-values="fieldValues"
                        @load="showFields = true"
                        @complete="handleFormSubmitted" />
                </div>
                <div v-else>
                    <p>Thanks for submitted the form we'll get in touch soon to give you access to the analyzer features</p>
                </div>
            </div>
        </template>
    </styled-slideout>
</template>

<script>
import StyledSlideout from '@/components/globals/StyledSlideout';
import HubSpotForm from '../globals/HubSpotForm.vue';
import { mapGetters, mapState } from 'vuex';
export default {
  components: {
    StyledSlideout,
    HubSpotForm,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      showFields: true,
      formSubmitted: false,
    };
  },
  methods: {
    handleFormSubmitted() {
      this.showFields = false;
      this.formSubmitted = true;
    },
  },
  computed: {
    ...mapGetters(['userIsAdmin']),
    ...mapState({
      user: (state) => state.user.user,
      stateDealers: (state) => state.dealer.dealers,
      currentDealer: (state) => state.dealer.currentDealer,
      currentAgency: (state) => state.agency.currentAgency,
      newTicketData: (state) => state.support.newTicketData,
    }),
    fieldValues() {
      return {
        'TICKET.agency_id': this.currentAgency.id,
        'TICKET.agency_name': this.currentAgency.name,
        'email': this.user.email,
        'name': this.user.name
      };
    },
  },
};
</script>
<style scoped>
.form-container {
  padding: 30px;
}
.form-description {
  margin-top: 15px;
  margin-bottom: 15px;
}
</style>