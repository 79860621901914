<template>
    <div class="container">
        <div class="page-title">
            <h3>MarketAnalyzer</h3>
        </div>
        <v-card>
            <div class="card-wrapper">
                <div class="custom-row">
                    <div class="custom-column">
                        <div class="card-title">
                            <h1>Market Analyzer</h1>
                        </div>
                        <p>
                            Discover and validate more opportunities across various
                            advertising channels for dealers in any market. Automatically
                            generate key insights that help identify which channels are
                            relevant to your client's markets and how much ad spend should be
                            distributed where; in order to optimize + maximize their target
                            audience on each channel. Unlock powerful data, like potential
                            results for each channel, that is critical for making data-driven
                            decisions that increase your client's ROI.
                        </p>
                        <div class="custom-row">
                            <div class="custom-column">
                                <ul class="card-list">
                                    <li>
                                        <span class="card-list-content">
                                            Target Market Analysis
                                        </span>
                                    </li>
                                    <li>
                                        <span
                                            class="card-list-content">Suggested Monthly Spend</span>
                                    </li>
                                    <li>
                                        <span class="card-list-content">Potential Results</span>
                                    </li>
                                </ul>
                            </div>
                            <div class="custom-column">
                                <ul class="card-list">
                                    <li>
                                        <span class="card-list-content">
                                            Market Inventory Breakdown
                                        </span>
                                    </li>
                                    <li>
                                        <span class="card-list-content">Market Competitors</span>
                                    </li>
                                    <li>
                                        <span class="card-list-content">User Demographics</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="selection-wrapper">
                            <button
                                class="custom-button"
                                @click="sessionAccessFormSlideoutOpen = true">
                                I need this
                            </button>
                            <div
                                class="custom-link"
                                @click="redirectToAnalyzer">
                                See it in action
                            </div>
                        </div>
                    </div>
                    <div class="custom-column image-column">
                        <img
                            src="/img/MA_screenshot.png"
                            width="100%"
                            alt="">
                    </div>
                </div>
                <session-access-form-slideout
                    v-model="sessionAccessFormSlideoutOpen"
                    :value="true" />
            </div>
        </v-card>
    </div>
</template>
<script>
import SessionAccessFormSlideout from './SessionAccessFormSlideout';
import { getMarketAnalyzerBaseUrl } from '@/helpers/marketAnalyzerHelpers';
export default {
  components: {
    SessionAccessFormSlideout,
  },
  data() {
    return {
      sessionAccessFormSlideoutOpen: false,
    };
  },
  methods: {
      redirectToAnalyzer() {
          window.open(getMarketAnalyzerBaseUrl(), '_blank').focus();
      }
  },
  title: 'Sessions Reports Access Control',
};
</script>
<style lang="scss" scoped>
.page-title {
  padding: 10px 10px 20px 0px;
}
.page-title h3 {
  font-weight: 400;
  font-size: 18px;
}
.card-wrapper {
  padding: 79px 0px 44px 87px;
}

.card-title h1 {
  text-transform: uppercase;
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 19px;
}

.card-content {
  text-align: left;
  font-size: 14px;
}
.custom-column {
  float: left;
  width: 50%;
}

.custom-row:after {
  content: "";
  display: table;
  clear: both;
}
.card-list li {
  margin-bottom: 8px;
}

li .card-list-content {
  margin-left: 15px;
}
.selection-wrapper {
  text-align: center;
  margin-top: 60px;
}

.custom-button {
  background-color: #00a2f1;
  color: #fff;
  padding: 9px 20px;
  font-size: 20px;
  border-radius: 4%;
}

.custom-link {
  color: #00a2f1;
  font-size: 16px;
  margin-top: 17px;
  cursor: pointer;
  text-decoration: underline;
}
</style>