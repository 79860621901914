<template>
    <div>
        <get-session-access />
    </div>
</template>
<script>
import GetSessionAccess from './GetSessionAccess';


export default {
  components: {
    GetSessionAccess,
  },
  title: 'Sessions Reports Access Control',
};
</script>
<style scoped>
.custom-column {
  width: 65%;
}

.right-column {
  width: 35%;
  padding: 15px;
}

.custom-row {
  display: flex;
  flex-direction: row;
}

.left-bordered {
  border-right: solid 1px #dee2e5;
  padding: 30px;
}

.card {
  margin-top: 80px;
}
</style>
